import React from 'react';
import {useNavigate} from 'react-router-dom';

import {IconButton, Theme, useMediaQuery} from '@mui/material';

import {HelpIcon, PlusIcon, SettingsIcon} from '../../../../assets/icons';
import {Button, HeaderPortal, PortalHeader} from '../../../../components';
import {ACCOUNT, RouteClientPortal} from '../../../../constants';
import {ActionTypes, useStateContext} from '../../../../contexts';
import {useAuth} from '../../../../hooks';
import theme from '../../../../theme';

const headerListItems = [
    {
        link: RouteClientPortal.myRoles,
        text: 'My Roles'
    },
    {
        link: RouteClientPortal.myMatches,
        text: 'My Matches'
    },
    {
        link: RouteClientPortal.myHires,
        text: 'My Hires'
    },
    {
        link: RouteClientPortal.mySpendings,
        text: 'My Spendings'
    }
];

const profileListItems = [
    {
        link: `${RouteClientPortal.profileSettings}/${ACCOUNT}`,
        icon: <SettingsIcon/>,
        text: 'Account Settings'
    },
    {
        link: RouteClientPortal.help,
        icon: <HelpIcon/>,
        text: 'Help'
    }
];

export const ClientPortalHeader = () => {
    const {isImpersonal} = useAuth();
    const {state: {openMobileMenu}, dispatch} = useStateContext();
    const navigate = useNavigate();

    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const handleAddRole = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (openMobileMenu) {
            document.body.classList.remove('header-menu-open');
            dispatch({type: ActionTypes.OPEN_MOBILE_MENU, payload: false});
        }
        navigate(RouteClientPortal.myRolesNew);
    };

    return (
        <PortalHeader
            chatLink={RouteClientPortal.inbox}
            headerListItems={headerListItems}
            profileListItems={profileListItems}
        >
            {!lgDown ? (
                <HeaderPortal.HeaderNotifItem>
                    <Button
                        disabled={isImpersonal}
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.purple.main,
                            boxShadow: '0 10px 20px rgba(126, 95, 255, 0)',
                            transition: '.3s',
                            '&:hover': {
                                backgroundColor: theme.palette.purple.main,
                                boxShadow: '0 10px 20px rgba(126, 95, 255, 0.2)'
                            }
                        }}
                        variant="contained"
                        onClick={handleAddRole}
                    >
                        + Add Role
                    </Button>
                </HeaderPortal.HeaderNotifItem>
            ) : (
                <IconButton
                    sx={{
                        padding: '20px',
                        backgroundColor: theme.palette.purple.main,
                        boxShadow: '0 10px 20px rgba(126, 95, 255, 0)',
                        transition: '.3s',
                        position: 'absolute',
                        top: 'calc(100vh - 104px)',
                        right: '40px',
                        '&:hover': {
                            backgroundColor: theme.palette.purple.main,
                            boxShadow: '0 10px 20px rgba(126, 95, 255, 0.2)'
                        },
                        'svg': {
                            width: '24px',
                            height: '24px'
                        },
                        'svg path': {
                            fill: theme.palette.white.main
                        }
                    }}
                    onClick={handleAddRole}
                >
                    <PlusIcon/>
                </IconButton>
            )}
        </PortalHeader>
    );
};
